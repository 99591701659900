body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.submit {
    background-color: #81c784;
    color: #f3f3f3;
    border: none;
    font-size: 16px;
    padding: 12px;
    height: 24px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

html,
body,
#root {
    height: 100%;
}

main {
    margin: 0 auto;
    max-width: 50em;
    padding: 4em 1em;
}

.shadowbox {
    margin: 12px 0px;
    padding: 12px;
    background-color: rgba(0, 0, 0, 0.01);
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.question {
    min-height: 120px;
}

#sponsors {
    display: flex;
    justify-content: center;
    align-items: center;
}

#sponsors > div {
    flex: 1 1 0px;
    padding: 24px;
}

#sponsors img {
    max-width: 100%;
}

#links {
    text-align: right;
}

#links a {
    color: #333;
    text-decoration: none;
    margin: 0 12px;
    position: relative;
}

#links a:first-child::after {
    content: "";
    position: absolute;
    right: -12px;
    height: 100%;
    width: 1px;
    background-color: #333;
}

ul {
    margin: 0;
    padding: 0;
}

ul li {
    list-style: none;
    margin: 12px 0px;
}

#pastEvaluations {
    margin: 24px 0px;
}

#pastEvaluations li {
    cursor: pointer;
}

img {
    max-width: 100%;
}
