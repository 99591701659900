.button-container {
    display: flex;
    height: 56px;
    padding: 24px 0px;
    position: relative;
}

.prev {
    border: thin solid #81c784;
    background: transparent;
    color: #333;
    left: 0;
}

.next {
    right: 0;
}

.prev,
.next {
    position: absolute;
    top: 0;
}
