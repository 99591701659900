.inputField {
  width: 100%;
  height: 56px;
  margin: 6px 0;
  border-radius: 4px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  transition: 0.3s all;
}
.inputField:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
}
.inputField.longText {
  min-height: 200px;
}
.inputField.longText .quill {
  height: 80%;
}
.inputField:hover {
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
}
.inputField input,
.inputField textarea,
.inputField select {
  width: calc(100% - 32px);
  height: 56px;
  position: relative;
  padding: 0px 16px;
  border: none;
  border-radius: 4px;
  font-family: "Gotham SSm A", "Gotham SSm B", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  background-color: transparent;
  color: #282828;
  outline: none;
  box-shadow: 0px 4px 20px 0px transparent;
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
  -webkit-appearance: none;
}
.inputField input:-internal-autofill-selected, .inputField input:input:-webkit-autofill, .inputField input:input:-webkit-autofill:hover, .inputField input:input:-webkit-autofill:focus, .inputField input:textarea:-webkit-autofill, .inputField input:textarea:-webkit-autofill:hover, .inputField input:textarea:-webkit-autofill:focus, .inputField input:select:-webkit-autofill, .inputField input:select:-webkit-autofill:hover, .inputField input:select:-webkit-autofill:focus,
.inputField textarea:-internal-autofill-selected,
.inputField textarea:input:-webkit-autofill,
.inputField textarea:input:-webkit-autofill:hover,
.inputField textarea:input:-webkit-autofill:focus,
.inputField textarea:textarea:-webkit-autofill,
.inputField textarea:textarea:-webkit-autofill:hover,
.inputField textarea:textarea:-webkit-autofill:focus,
.inputField textarea:select:-webkit-autofill,
.inputField textarea:select:-webkit-autofill:hover,
.inputField textarea:select:-webkit-autofill:focus,
.inputField select:-internal-autofill-selected,
.inputField select:input:-webkit-autofill,
.inputField select:input:-webkit-autofill:hover,
.inputField select:input:-webkit-autofill:focus,
.inputField select:textarea:-webkit-autofill,
.inputField select:textarea:-webkit-autofill:hover,
.inputField select:textarea:-webkit-autofill:focus,
.inputField select:select:-webkit-autofill,
.inputField select:select:-webkit-autofill:hover,
.inputField select:select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  background-color: transparent;
}
.inputField input + label,
.inputField textarea + label,
.inputField select + label {
  position: absolute;
  top: 24px;
  left: 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  color: #000;
  opacity: 1;
  pointer-events: none;
  transition: 0.1s all ease-in-out;
}
.inputField input:focus + label, .inputField input.hasValue + label,
.inputField textarea:focus + label,
.inputField textarea.hasValue + label,
.inputField select:focus + label,
.inputField select.hasValue + label {
  top: -2px;
}
.inputField input ~ span#showPasswordIcon,
.inputField textarea ~ span#showPasswordIcon,
.inputField select ~ span#showPasswordIcon {
  position: absolute;
  top: 12px;
  right: 16px;
  cursor: pointer;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inputField textarea {
  resize: none;
  height: 100%;
}
.inputField option {
  width: 100%;
}
